import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled, { keyframes } from 'styled-components'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.div`
  padding: 100px 0;

  @media (max-width: 544px) {
    padding: 40px 0;
  }
`

const Head = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Title = styled.h1`
  flex: 1;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(37)};
  margin: 0;

  @media (max-width: 768px) {
    font-size: ${props => props.theme.unit.getRem(24)};
    margin-bottom: 24px;
  }
`

const Navigation = styled.div`
  min-width: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
`

const Cards = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 72px;
  margin-top: 48px;
  transform: translate(${props => props.translate}px, 0);
  transition: 750ms ease;
`

const fadeOutLeft = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
`

const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 16px 30px 0 rgba(209, 219, 231, 0.34);
  padding: 24px 40px 48px 40px;
  width: 448px;
  min-height: 296px;
  transition: 750ms ease;
  opacity: 0;

  &.prev {
    animation-name: ${fadeOutLeft};
    animation-duration: 750ms;
    animation-fill-mode: both;
  }

  &.active-card {
    opacity: 1;

    & ~ .slide {
      opacity: 1;
    }
  }

  .header {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;

    .profile {
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }

    .stars {
      max-width: 144px;
      justify-self: center;
    }
  }

  .content {
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 400;
    min-height: 168px;
    margin: 24px 0 48px 0;
  }

  .user {
    font-size: ${props => props.theme.unit.getRem(18)};
    font-weight: 400;

    .name {
      color: ${props => props.theme.colors.black};
      display: block;
    }

    .additional {
      color: ${props => props.theme.colors.gray};
      display: black;
    }
  }

  @media (max-width: 768px) {
    min-width: auto;
    width: calc(100vw - 40px - 32px);
    padding: 32px 16px;

    .header {
      grid-template-columns: 1fr;
      justify-items: center;

      .profile {
        margin-bottom: 16px;
      }
    }

    .content {
      margin: 24px 0 32px 0;
    }
  }
`

const data = [
  {
    key: 'steveMoskowitz',
    content:
      "I've never seen anything like it before. Ever since bringing on Contact Smarter, my business actually has become more smooth. Simplicity is key. I love it, because as a marketing agency, having everything in one place is very important.",
    name: 'Steve Moskowitz',
    additional: 'JSU Media',
  },
  {
    key: 'treyCamp',
    content:
      'It’s really cool to bring on a client and do a quick training, and they have enough knowledge that they can go in there and use the system. To me, that’s huge!',
    name: 'Trey Camp',
    additional: 'The Data Directive',
  },
  {
    key: 'brandonWong',
    content:
      'Within the first week, we had another thousand dollars a month in the pipeline for our client and we literally sent a single broadcast with the software. That much value created off of just something so simple.',
    name: 'Brandon Wong',
    additional: 'Faucet Marketing and Lead Generation',
  },
  {
    key: 'kejdaGjermani',
    content:
      'If you need to put the end client or yourself in touch with the leads as fast, as quickly as possible over the phone, this is really the solution.',
    name: 'Kejda Gjermani',
    additional: 'Predictive Intent Marketing',
  },
  {
    key: 'chadGray',
    content:
      'Try it. It’s going to be something that is going to make your life way easier and way more efficient.',
    name: 'Chad Gray',
    additional: 'Perfect Online Marketing',
  },
]

const Testimonials = () => {
  const [currentCard, setCurrentCard] = useState(0)
  const [translate, setTranslate] = useState(0)
  const [htmlActiveArr, setHtmlActiveArr] = useState(null)

  const onPrev = () => {
    const resetToVeryBack = currentCard === 0
    const index = resetToVeryBack ? data.length - 1 : currentCard - 1

    const move = translate - (cardWidth + 72 || 0)
    if (move >= 0) setTranslate(move)

    setCurrentCard(index)
  }

  const onNext = () => {
    const resetIndex = currentCard === data.length - 1
    const index = resetIndex ? 0 : currentCard + 1

    setTranslate(translate + (cardWidth + 72 || 0))
    setCurrentCard(index)
  }

  const getClassName = index => {
    if (index === currentCard) return 'slide active-card'
    if (index === currentCard - 1) return 'slide prev'

    return 'slide'
  }

  useEffect(() => {
    setHtmlActiveArr(Array.from(document.getElementsByClassName('active-card')))
  }, [])

  let cardWidth
  if (!_.isEmpty(htmlActiveArr)) {
    cardWidth = htmlActiveArr[0].getBoundingClientRect().width
  }

  const images = useStaticQuery(graphql`
    query {
      steveMoskowitz: file(
        relativePath: { eq: "clients/1-steve-moskowitz.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      chadGray: file(relativePath: { eq: "clients/2-chad-gray.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brandonWong: file(relativePath: { eq: "clients/3-brandon-wong.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kejdaGjermani: file(
        relativePath: { eq: "clients/4-kejda-gjermani.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      treyCamp: file(relativePath: { eq: "clients/5-trey-camp.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Head>
        <Title>Don’t just take our word for it</Title>
        <Navigation>
          <img
            alt="on prev"
            onClick={onPrev}
            src={require('../images/left-arrow.svg')}
          />
          <img
            alt="on next"
            onClick={onNext}
            src={require('../images/right-arrow.svg')}
          />
        </Navigation>
      </Head>

      <Cards translate={`-${currentCard * (cardWidth + 72 || 0)}`}>
        {data.map((testimonial, i) => {
          return (
            <Card key={testimonial.key} className={getClassName(i)}>
              <div className="header">
                <Img
                  className="profile"
                  alt="profile"
                  fadeIn={true}
                  fluid={images[testimonial.key].childImageSharp.fluid}
                />
                <img
                  className="stars"
                  alt="stars"
                  src={require('../images/five-stars.svg')}
                />
              </div>

              <div className="content">{testimonial.content}</div>

              <div className="user">
                <span className="name">{testimonial.name}</span>
                <span className="additional">{testimonial.additional}</span>
              </div>
            </Card>
          )
        })}
      </Cards>
    </Wrapper>
  )
}

export default Testimonials
