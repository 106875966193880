import React, { useEffect } from 'react'
import styled from 'styled-components'

import Container from '../components/Container'
import Footer from '../components/Footer'
import GetStartedBanner from '../components/GetStartedBanner'
import Navbar from '../components/Navbar'
import Testimonials from '../components/Testimonials'

import FrequentlyQuestions from '../components/Pricing/FrequentlyQuestions'
import Pricing from '../components/Pricing/Plans'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 96px;

  @media (max-width: 544px) {
    padding-top: 40px 0;
  }
`

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
`

const Title = styled.h1`
  margin: 0;
  padding: 0;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(45)};
  text-align: center;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(26)};
  }
`

const Description = styled.div`
  margin: 24px 0 80px 0;
  color: ${props => props.theme.colors.gray};
  font-size: ${props => props.theme.unit.getRem(21)};
  text-align: center;

  @media (max-width: 544px) {
    margin: 24px 0 56px 0;
    font-size: ${props => props.theme.unit.getRem(18)};
  }
`

const Agencies = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Segment tracking page
      window.analytics.track('PricingPage Loaded')
      // Facebook pixel tracking page
      window.fbq('track', 'PricingPage Loaded')
    }
  }, [])

  return (
    <React.Fragment>
      <Navbar black />

      <Container>
        <Wrapper>
          <Title>Plans & Pricing</Title>
          <Description>
            ContactSmarter is perfect for individuals and teams of all sizes.
            Start for free.
          </Description>
        </Wrapper>
      </Container>

      <Container wide>
        <PricingWrapper>
          <Pricing />
        </PricingWrapper>
      </Container>

      <Container>
        <Testimonials />
      </Container>

      <Container>
        <FrequentlyQuestions />
      </Container>

      <Container>
        <GetStartedBanner />
      </Container>

      <Footer />
    </React.Fragment>
  )
}

export default Agencies
