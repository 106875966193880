import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 312px) minmax(auto, 640px);
  justify-content: space-between;
  grid-gap: 24px;
  padding: 100px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 544px) {
    padding: 40px 0;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.unit.getRem(37)};
  margin: 0;
  padding: 0;

  @media (max-width: 544px) {
    font-size: ${props => props.theme.unit.getRem(24)};
  }
`

const Help = styled.div`
  color: ${props => props.theme.colors.gray};
  margin-top: 24px;

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    display: inline-block;
  }

  @media (max-width: 544px) {
    margin-bottom: 56px;
  }
`

const Question = styled.div`
  border-bottom: 1px solid #e5e7ec;
  margin-bottom: 48px;

  :nth-last-child(1) {
    margin-bottom: 0;
  }

  .title {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 600;
    margin: 0;
    margin-bottom: 24px;

    @media (max-width: 544px) {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }

  .content {
    color: ${props => props.theme.colors.gray};
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 400;
    margin-bottom: 48px;

    @media (max-width: 544px) {
      font-size: ${props => props.theme.unit.getRem(18)};
    }
  }
`

const FrequentlyQuestions = () => {
  return (
    <Wrapper>
      <div>
        <Title>Frequently asked questions</Title>
        <Help>
          Need more help? Check out our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://intercom.help/gethelp/en"
          >
            Help Center.
          </a>
        </Help>
      </div>

      <div>
        <Question>
          <h3 className="title">How does the free trial work?</h3>
          <div className="content">
            When you sign up for ContactSmarter as a new subscriber or a
            returning subscriber who has not yet had a free trial, you’ll have
            7-days trial!
          </div>
        </Question>

        <Question>
          <h3 className="title">What’s included in the trial?</h3>
          <div className="content">
            You will have full access to all the features and functionalities in
            the plan that you select.
          </div>
        </Question>

        <Question>
          <h3 className="title">What happens at the end of my free trial?</h3>
          <div className="content">
            If you do not cancel before the end of your free trial period, your
            account will automatically be charged the monthly subscription fee
            depending on which subscription you chose.
          </div>
        </Question>

        <Question>
          <h3 className="title">Can I change my plan later?</h3>
          <div className="content">
            Yes, you can change your plan at any time. You can upgrade or
            downgrade to a different plan at any time. If you change your plan
            in the middle of the billing cycle your monthly charge will be
            prorated on the time you have left on your current plan.
          </div>
        </Question>

        <Question>
          <h3 className="title">Can I cancel my account at any time?</h3>
          <div className="content">
            Yes. If you ever decided that ContactSmarter isn’t the best
            automated leads follow-up system for your business, simply reach our
            support team so they can help you with that.
          </div>
        </Question>
      </div>
    </Wrapper>
  )
}

export default FrequentlyQuestions
