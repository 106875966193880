import React from 'react'
import styled, { css } from 'styled-components'

const features = [
  {
    label: 'Instant effortless setup',
    plans: { basic: true, core: true, pro: true },
  },
  {
    label: '3 channel followup (Email, SMS, Voicemails)',
    plans: { basic: true, core: true, pro: true },
  },
  {
    label: 'Mobile app',
    plans: { basic: true, core: true, pro: true },
  },
  {
    label: 'Easy team management and lead distribution',
    plans: { basic: true, core: true, pro: true },
  },
  {
    label: 'Advanced reporting',
    plans: { basic: true, core: true, pro: true },
  },
  {
    label: 'Mass lead import',
    plans: { basic: false, core: true, pro: true },
  },
  {
    label: 'Mass message broadcast',
    plans: { basic: false, core: true, pro: true },
  },
  {
    label: 'Automated outbound sequences (Emails, Texts, Voicemails)',
    plans: { basic: false, core: true, pro: true },
  },
  {
    label: 'Forced calling',
    plans: { basic: false, core: true, pro: true },
  },
  {
    label: 'Auto dialer',
    plans: { basic: false, core: true, pro: true },
  },
  {
    label: 'Live chat support',
    plans: { basic: false, core: true, pro: 'For admin only' },
  },
  {
    label: 'Your own brand / colors',
    plans: { basic: false, core: false, pro: true },
  },
  {
    label: 'Custom domain',
    plans: { basic: false, core: false, pro: true },
  },
  {
    label: 'Unlimited users with your own Twilio / Sendgrid / Slybroadcast',
    plans: { basic: false, core: false, pro: true },
  },
  {
    label: 'Done for you back-end integration',
    plans: { basic: false, core: false, pro: true },
  },
  {
    label: 'Done for you setup (including setting up your first Ads)',
    plans: { basic: false, core: true, pro: false },
  },
]

const Prices = styled.div`
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  background-color: #fafafa;

  .head__container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: auto;
    background-color: ${props => props.theme.colors.white};

    @media (min-width: 992px) {
      grid-template-columns: 272px repeat(3, 240px);
    }
  }

  .features__container {
    display: none;
    grid-auto-flow: row;
    grid-template-columns: 272px repeat(3, 240px);

    @media (min-width: 992px) {
      display: grid;
    }
  }

  .features__container-mobile {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 4fr 1fr;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .feature-label {
    border-bottom: solid 1px rgba(229, 231, 236, 0.5);
    box-shadow: 0 16px 32px 0 rgba(209, 219, 231, 0.34);
    padding: 24px;
    background-color: ${props => props.theme.colors.primaryDarker};
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.unit.getRem(14)};

    &.last {
      border-bottom: 0;
    }
  }

  .feature-value {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px rgba(229, 231, 236, 0.5);
    background-color: #fafafa;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(14)};
    text-align: center;

    &.last {
      border-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    .feature-value__core {
      border-right: solid 1px ${props => props.theme.colors.primary};
      border-bottom: 0;
      border-left: solid 1px ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.white};

      &.last {
        border-bottom: solid 1px ${props => props.theme.colors.primary};
        border-radius: 0 0 4px 4px;
      }
    }
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  background-color: ${props => props.theme.colors.white};
  text-align: center;

  :not(:first-child) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    align-items: flex-start;
    padding: 24px;
    padding-bottom: 16px;
    text-align: left;

    :not(:first-child) {
      margin-top: 0;
    }

    &:first-of-type {
      grid-column: 2 / 3;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 8px;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(18)};
  }

  ${props =>
    props.featured &&
    css`
      @media (min-width: 992px) {
        border: solid 1px ${props => props.theme.colors.primary};
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
      }

      .popular {
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props => props.theme.colors.primary};
        font-weight: 600;

        @media (min-width: 992px) {
          justify-content: flex-start;
        }

        h3 {
          margin-bottom: 0;
        }

        div {
          margin-left: 16px;

          img {
            margin-right: 4px;
            height: 12px;
          }

          span {
            font-size: ${props => props.theme.unit.getRem(12)};
          }
        }
      }
    `}

  .card-info {
    margin-bottom: 16px;
  }

  .price {
    display: block;
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.unit.getRem(21)};
    font-weight: 600;

    &.pro {
      color: ${props => props.theme.colors.green};
    }
  }

  label {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.unit.getRem(12)};
    font-weight: 400;
    margin-top: 8px;
  }

  .button {
    padding: 8px 0;
    border-radius: 5px;
    background-color: ${props =>
      props.isContactSales
        ? props.theme.colors.green
        : props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    width: 172px;
    transition: background-color 250ms ease-in;
    font-size: ${props => props.theme.unit.getRem(11)};
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    :hover {
      background-color: ${props =>
        props.isContactSales
          ? props.theme.colors.hover.green
          : props.theme.colors.hover.primary};
    }

    :focus {
      background-color: ${props =>
        props.isContactSales
          ? props.theme.colors.focus.green
          : props.theme.colors.focus.primary};
    }
  }
`

const FeatureValue = ({ plan, value }) => {
  if (plan === 'pro' && value === true)
    return <img alt="check" src={require('../../images/check-green.svg')} />

  if (value === true)
    return <img alt="check" src={require('../../images/check.svg')} />

  if (value === false)
    return <img alt="cross" src={require('../../images/cross.svg')} />

  if (typeof value === 'string')
    return <span style={{ padding: 4 }}>{value}</span>

  return null
}

const Pricing = () => {
  return (
    <Prices>
      <div className="head__container">
        {/* -- Start Basic plan block -- */}
        <Card>
          <h3>Basic</h3>
          <div className="card-info">
            <div className="price">$149 / mo</div>
            <label>per user</label>
          </div>

          <a
            href="https://app.contactsmarter.com/signup?selectedPlan=basic"
            className="button"
          >
            START YOUR FREE TRIAL
          </a>
        </Card>

        <div className="features__container-mobile">
          {features.map((feature, i) => (
            <React.Fragment key={i}>
              <div
                className={`feature-label ${i === features.length - 1 &&
                  'last'}`}
              >
                {feature.label}
              </div>
              <div
                className={`feature-value ${i === features.length - 1 &&
                  'last'}`}
              >
                <FeatureValue value={feature.plans.basic} />
              </div>
            </React.Fragment>
          ))}
        </div>
        {/* -- End Basic plan block -- */}

        {/* -- Start Core plan block -- */}
        <Card featured>
          <div className="popular">
            <h3>Core</h3>
            <div>
              <img alt="star" src={require('../../images/star.svg')} />
              <span>POPULAR</span>
            </div>
          </div>
          <div className="card-info">
            <div className="price">$197 / mo</div>
            <label>per user</label>
          </div>

          <a
            href="https://app.contactsmarter.com/signup?selectedPlan=core"
            className="button"
          >
            START YOUR FREE TRIAL
          </a>
        </Card>

        <div className="features__container-mobile">
          {features.map((feature, i) => (
            <React.Fragment key={i}>
              <div
                className={`feature-label ${i === features.length - 1 &&
                  'last'}`}
              >
                {feature.label}
              </div>
              <div
                className={`feature-value ${i === features.length - 1 &&
                  'last'}`}
              >
                <FeatureValue value={feature.plans.core} />
              </div>
            </React.Fragment>
          ))}
        </div>
        {/* -- End Core plan block -- */}

        {/* -- Start Pro plan block -- */}
        <Card isContactSales>
          <h3>Unlimited Whitelabel</h3>
          <div className="card-info">
            <div className="price pro">$297 / mo</div>
            <label>per team</label>
          </div>

          <a
            href="https://app.contactsmarter.com/signup?selectedPlan=pro"
            className="button"
          >
            START YOUR FREE TRIAL
          </a>
        </Card>

        <div className="features__container-mobile">
          {features.map((feature, i) => (
            <React.Fragment key={i}>
              <div
                className={`feature-label ${i === features.length - 1 &&
                  'last'}`}
              >
                {feature.label}
              </div>
              <div
                className={`feature-value ${i === features.length - 1 &&
                  'last'}`}
              >
                <FeatureValue value={feature.plans.pro} plan="pro" />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* -- End Pro plan block -- */}

      {/* -- Start Features block -- */}
      {/* -- (desktop version only. Mobile version is in plans block). -- */}
      <div className="features__container">
        {features.map((feature, i) => (
          <React.Fragment key={i}>
            <div
              className={`feature-label ${i === features.length - 1 && 'last'}`}
            >
              {feature.label}
            </div>
            <div
              className={`feature-value ${i === features.length - 1 && 'last'}`}
            >
              <FeatureValue value={feature.plans.basic} />
            </div>
            <div
              className={`feature-value feature-value__core ${i ===
                features.length - 1 && 'last'}`}
            >
              <FeatureValue value={feature.plans.core} />
            </div>
            <div
              className={`feature-value ${i === features.length - 1 && 'last'}`}
            >
              <FeatureValue value={feature.plans.pro} plan="pro" />
            </div>
          </React.Fragment>
        ))}
      </div>
      {/* -- End Features block -- */}
    </Prices>
  )
}

export default Pricing
